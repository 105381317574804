import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [];

export const dictionary = {
		"/": [~7],
		"/(static)/accessibilite": [17],
		"/admin": [26,[3]],
		"/admin/contributions": [27,[3]],
		"/admin/moderation": [28,[3]],
		"/admin/services": [29,[3]],
		"/admin/services/[slug]": [30,[3]],
		"/admin/structures": [31,[3]],
		"/admin/structures/creer": [33,[3]],
		"/admin/structures/[slug]": [32,[3]],
		"/annuaire-collectivite": [34],
		"/auth/connexion": [35,[4]],
		"/auth/deconnexion": [36,[4]],
		"/auth/ic-callback": [37,[4]],
		"/auth/ic-connect": [38,[4]],
		"/auth/ic-update": [39,[4]],
		"/auth/invitation": [40,[4]],
		"/auth/rattachement": [41,[4]],
		"/(static)/cgu": [18],
		"/(static)/cgu/cgu-20221213": [19],
		"/(static)/cgu/cgu-20230515": [20],
		"/(static)/cgu/cgu-20230720": [21],
		"/(static)/cgu/validation": [22],
		"/contribuer": [42],
		"/contribuer/merci": [43],
		"/contribuer/saisie": [44],
		"/favoris": [45],
		"/(static)/mentions-legales": [23],
		"/mes-alertes": [46],
		"/mes-alertes/[searchId]": [47],
		"/(modeles-services)/modeles/creer": [10],
		"/(modeles-services)/modeles/[slug]": [8],
		"/(modeles-services)/modeles/[slug]/editer": [9],
		"/mon-compte": [48,[5]],
		"/(static)/nos-partenaires": [~24],
		"/orientations": [49],
		"/(static)/politique-de-confidentialite": [25],
		"/recherche": [50],
		"/(modeles-services)/services/creer": [16],
		"/(modeles-services)/services/[slug]": [11],
		"/(modeles-services)/services/[slug]/editer": [12],
		"/(modeles-services)/services/[slug]/orienter": [13,[2]],
		"/(modeles-services)/services/[slug]/orienter/demande": [14,[2]],
		"/(modeles-services)/services/[slug]/orienter/merci": [15,[2]],
		"/stats": [51],
		"/structures/[slug]": [52,[6]],
		"/structures/[slug]/antennes": [53,[6]],
		"/structures/[slug]/collaborateurs": [54,[6]],
		"/structures/[slug]/editer": [55,[6]],
		"/structures/[slug]/modeles": [56,[6]],
		"/structures/[slug]/services": [57,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';